import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { Subscription } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-add-ticket-attendee-dialog',
	templateUrl: './add-ticket-attendee-dialog.component.html',
	styleUrls: ['./add-ticket-attendee-dialog.component.css'],
	standalone: false,
})
export class AddTicketAttendeeDialogComponent implements OnInit, OnDestroy {
	private subscription: Subscription;

	public id: number;
	public title: string;
	public tickets: any;
	public partner?: number;

	public form: UntypedFormGroup;
	public isValidFormSubmitted = null;
	public error: any;

	public test = [1, 2, 3];

	constructor(
		public dialogRef: MatDialogRef<AddTicketAttendeeDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data,
		private apiService: ApiService,
		private fb: UntypedFormBuilder,
		private errorHandler: ErrorHandlerService
	) {
		this.id = data.id;
		this.title = data.type;
		this.partner = data.partner ? data.partner : '';
	}

	ngOnInit(): void {
		this.initData();
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	initData(): void {
		this.subscription = this.apiService.getData(`conference/conferences/${this.id}/tickets/select`).subscribe(
			(rest: any) => {
				console.log(rest);
				this.tickets = rest.data;
				this.initForm();
			},
			(err: HttpErrorResponse) => {
				this.errorHandler.errorHandler(err, 'Get tickets');
			}
		);
	}

	initForm(): void {
		this.form = this.fb.group({
			givenName: ['', [Validators.required]],
			familyName: ['', [Validators.required]],
			email: ['', [Validators.required]],
			company: ['', []],
			ticket: ['', [Validators.required]],
		});
	}

	submit(): void {
		this.isValidFormSubmitted = false;

		if (this.form.value) {
			this.isValidFormSubmitted = true;
			this.onSave(this.form.value);
		}
	}

	onSave(data: any): void {
		this.dialogRef.close(data);
	}

	onClose(): void {
		this.dialogRef.close();
	}
}
