<div class="relative max-h-modal sm:w-full sm:max-w-lg">
	<div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
		<div class="sticky top-0 z-10 w-full bg-white">
			<h3 class="mb-2 text-lg font-medium leading-6 text-gray-900" id="modal-title">Ticket-Status wählen</h3>

		</div>
		<div class="relative max-h-modalInner overflow-y-auto overflow-x-hidden">
      <select
        id="status"
        #statusSelect
        class="mb-3 block w-full cursor-pointer appearance-none rounded border border-solid border-gray-500 bg-white px-4 py-3 leading-tight text-gray-700 placeholder-gray-600 ring-0 outline-none focus:outline-none"
      >
        <option value="" class="opacity-75" selected>
          Status wählen
        </option>
        @for (state of statusList; track state.id) {
          <option [value]="state.status" [selected]="status === state.status">
            {{ state.name }}
          </option>
        }
      </select>
		</div>
	</div>
	<div class="sticky bottom-0 bg-white px-4 pb-4 pt-3 sm:flex sm:flex-row-reverse sm:px-6 sm:pb-6">
    <button
      type="button"
      class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-400 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-500 focus:outline-none sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
      (click)="onSave(statusSelect)"
    >
      Speichern
    </button>
		<button
			type="button"
			class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-400 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-500 focus:outline-none sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
			(click)="onClose()"
		>
			Abbrechen
		</button>
	</div>
</div>
