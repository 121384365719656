import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-select-ticket-status-dialog',
	templateUrl: './select-ticket-status-dialog.component.html',
	styleUrls: ['./select-ticket-status-dialog.component.css'],
	standalone: false,
})
export class SelectTicketStatusDialogComponent implements OnInit, OnDestroy {
	public status: string = 'ENABLED';
	public statusList: Array<any> = [
		{
			id: 1,
			status: 'enabled',
			name: 'Aktiv',
		},
		{
			id: 2,
			status: 'disabled',
			name: 'Inaktiv',
		},
		{
			id: 3,
			status: 'refunded',
			name: 'Storniert',
		},
	];

	constructor(
		public dialogRef: MatDialogRef<SelectTicketStatusDialogComponent>,
		public dialog: MatDialog
	) {}

	ngOnInit(): void {}

	ngOnDestroy(): void {}

	onSave(data: any): void {
		this.dialogRef.close(data.value);
	}

	onClose(): void {
		this.dialogRef.close();
	}
}
